import React, { Fragment, useEffect, useState, useRef } from 'react';

import CriPicker from "./CriPicker"
import FlacsPicker from './FlacsPicker';
import NearPicker from "./NearPicker"
import FeePicker from './FeePicker';
import { BsEye } from "react-icons/bs";
import IconMonofocal from './images/iconMonofocal.png'
import IconToric from './images/iconToric.png'
import IconEdof from './images/iconEdof.png'
import IconToricEdof from './images/iconToricEdof.png'
import IconTrifocal from './images/iconTrifocal.png'
import IconToricTrifocal from './images/iconToricTrifocal.png'


export default function LensPicker ( props ) {

  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const selectedOptionRef = useRef(null);

  useEffect(() => {
    if (isOpen && selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isOpen]);

  const handleOptionClick = (l) => {
    props.setLensOption(l);
    setIsOpen(false);
    props.setLensOption(l)
  };
  

  //Filter each lens by patient information parameters and then create a list of all valid lens option names

  // Use equipmentPreference for astigmatism
  let equipmentPreferenceAstig =
    props.equipmentPreference === props.biometer
    ? props.biometerAstig
    : props.equipmentPreference === props.topographer
      ? props.topographerAstig
      : null
      // console.log(props.biometer)
      // console.log(props.topographer)
      // console.log(props.equipmentPreference)
      // console.log(equipmentPreference)
      // console.log(equipmentPreferenceAstig)


  // Create an array where we can push lenses with warnings
  let lensContraWarningList = [];
  let lensParamWarningList = [];


  // Get patient's contraindications
  let ptContraFilter = [];
  props.contraindications.map(item => {
    if(item.value === 'true'){
      return ptContraFilter.push(item.name)
    } else {
      return null
    }
  });

  // Use map to get a simple array of lens name with contraindications
  let lensDataFilter = props.lensData.length > 0
  ? props.lensData.map(itemY => {
      let testArr = []
      Object.entries(itemY.contraindications).map(itemZ => {
        if(itemZ[1].value === 'true'){
          return testArr.push(itemZ[1].name)
        } else {
          return null
        }
      })
      return [{name: itemY.lens_name, contraindications: testArr}];
    })
  : []
  


  //Map through lens name with contraindications array and filter out lenses where contraindication matches patient contraindications
  let lensOptionFilter = []
  lensDataFilter.map(item => {
    let lensContraindications = item[0].contraindications
    // console.log(lensContraindications)

    let ptContraindications = ptContraFilter
    // console.log(ptContraindications)

    let lensName = item[0].name
    // console.log(lensName)

    let res = lensContraindications.filter(cha =>
      ptContraindications.includes(cha)
      )
      if(res.length <= 0){
        return lensOptionFilter.push(lensName)
      } else {
        return lensContraWarningList.push({name: lensName, warning: res})
        //nb: can type {...res} to turn res arrays into objects
      }
    }
  )


  //Create an array where we can push qualifying lenses
  let mainLensList = [];
  
  //Map through users lenses pulled from the database
  let mainLensFilter = Object.entries(props.lensData).map((c) => {
      let lens = c[1];
      let warnings = [];
  
      let isPresbyopiaCorrecting = (lens.corrects_intermediate_vision === 'Yes' || lens.corrects_near_vision === 'Yes') &&
        equipmentPreferenceAstig * 1 >= lens.lens_astig_min * 1 &&
        (equipmentPreferenceAstig * 1 <= (lens.lens_astig_max * 1 + lens.lens_astig_residual * 1) ||
          (equipmentPreferenceAstig * 1 < lens.cri_max_astig * 1 && equipmentPreferenceAstig * 1 > lens.cri_min_astig * 1));
      if (lens.corrects_intermediate_vision === 'Yes' || lens.corrects_near_vision === 'Yes') {
        if (!isPresbyopiaCorrecting) {
          warnings.push(`The residual astigmatism is outside the range for this presbyopia-correcting lens`);
        }
      }
  
      let isNonPresbyopiaToric = (lens.corrects_intermediate_vision === 'No' && lens.corrects_near_vision === 'No') &&
        equipmentPreferenceAstig * 1 >= lens.lens_astig_min * 1 &&
        (equipmentPreferenceAstig * 1 <= (lens.lens_astig_max * 1 + lens.lens_astig_residual * 1) || lens.lens_astig_residual === 'dontuse');
      if (lens.corrects_intermediate_vision === 'No' && lens.corrects_near_vision === 'No' && lens.lens_astig_min > 0) {
        if (!isNonPresbyopiaToric) {
          warnings.push(`The residual astigmatism is outside the range for this toric lens`);
        }
      }
  
      let isSphericalLens = (lens.corrects_intermediate_vision === 'No' && lens.corrects_near_vision === 'No') &&
        lens.lens_astig_min == 0 &&
        lens.lens_astig_max == 0 &&
        lens.lens_astig_residual === 'dontuse';
  
      let validSphericalAberration = lens.k_sph_ab_residual === 'dontuse' ||
        (props.topographerSphAb * 1 + lens.lens_sph_ab * 1) >= (props.sphAbTarget * 1 - Math.abs(lens.k_sph_ab_residual * 1)) &&
        (props.topographerSphAb * 1 + lens.lens_sph_ab * 1) <= (props.sphAbTarget * 1 + Math.abs(lens.k_sph_ab_residual * 1));
      if (!validSphericalAberration) {
        warnings.push(`The residual spherical aberration limit for this lens is ${lens.k_sph_ab_residual*1} µm, and your eye would have a residual spherical aberration of ${Math.abs(lens.lens_sph_ab * 1 + props.topographerSphAb * 1).toFixed(2)} µm with this lens`);
      }
  
      let validHOA = lens.lens_hoa_max === 'dontuse' || props.topographerHoa * 1 <= lens.lens_hoa_max * 1;
      if (!validHOA) {
        warnings.push(`The corneal higher order aberration limit for this lens is ${lens.lens_hoa_max} µm, and the cornea of your eye has a higher order aberration of ${props.topographerHoa} µm`);
      }
  
      let validChordMu = lens.chord_mu_max === 'dontuse' || props.chordMuTotal * 1 <= lens.chord_mu_max * 1;
      if (!validChordMu) {
        warnings.push(`Chord mu > ${lens.chord_mu_max} mm`);
      }
  
      let validBiometerStdDev = lens.biometer_std_dev_max === 'dontuse' || props.biometerStdDev * 1 <= lens.biometer_std_dev_max * 1;
      if (!validBiometerStdDev) {
        warnings.push(`Biometer standard deviation of keratometry > ${lens.biometer_std_dev_max}`);
      }
  
      let validBiometerTopographerAstig = lens.biometer_topographer_astig_max === 'dontuse' ||
        equipmentPreferenceAstig * 1 <= lens.lens_astig_residual * 1 ||
        props.biometerTopographerAstigDiff * 1 <= lens.biometer_topographer_astig_max * 1;
      if (!validBiometerTopographerAstig) {
        warnings.push(`This lens does not work well if the astigmatism measured on the biometer and topographer measures more than ${lens.biometer_topographer_astig_max} D, and your eye measured ${props.biometerTopographerAstigDiff} D`);
      }
  
      let validBiometerTopographerAxis = lens.biometer_topographer_axis_max === 'dontuse' ||
        equipmentPreferenceAstig * 1 <= lens.lens_astig_residual * 1 ||
        props.biometerTopographerAxisDiff * 1 <= lens.biometer_topographer_axis_max * 1;
      if (!validBiometerTopographerAxis) {
        warnings.push(`This lens does not work well if the axis measured on the biometer and topographer measures more than ${lens.biometer_topographer_axis_max} degrees, and your eye measured ${props.biometerTopographerAxisDiff} degrees`);
      }

      
      // ============================================================================

      // Check if lens is monofocal (not presbyopia-correcting)
      let isMonofocal = 
      lens.corrects_intermediate_vision === "No" &&
      lens.corrects_near_vision === "No" &&
      lens.lens_astig_max === '0' &&
      lens.lens_astig_min === '0';

      if (isMonofocal) {
        // console.log(lens.lens_name)
        // Determine the best monofocal lens based on spherical aberration criteria
        let onlyMonoArr = Object.entries(props.lensData)
          .filter(([_, monoLens]) =>
            monoLens.corrects_intermediate_vision === "No" &&
            monoLens.corrects_near_vision === "No" &&
            monoLens.lens_astig_max === '0' &&
            monoLens.lens_astig_min === '0')
          .map(([_, monoLens]) => ({ name: monoLens.lens_name, sphAb: parseFloat(monoLens.lens_sph_ab), warnings: [] }));

          // console.log(onlyMonoArr)
          if (onlyMonoArr.length > 0) {
            // Find the lens with the spherical aberration closest to zero
            const closestToZeroLensSphAb = onlyMonoArr.reduce((acc, obj) =>
              Math.abs(obj.sphAb) < Math.abs(acc.sphAb) ? obj : acc
            );
            // closestToZeroLensSphAb now holds the lens object with the spherical aberration closest to zero
          
            // console.log(onlyMonoArr)
            
          // Find the lens that brings the residual spherical aberration closest to the target
          const absMinResSphAb = onlyMonoArr.reduce((acc, obj) => {
            const residual = Math.abs(props.sphAbTarget*1 - (obj.sphAb + props.topographerSphAb*1));
            const accResidual = Math.abs(props.sphAbTarget*1 - (acc.sphAb + props.topographerSphAb*1));
            return residual < accResidual ? obj : acc;
          });

          // Determine the best monofocal lens
          const bestMonoLens = (props.topographerHoa*1 > props.hoaMaxPreference*1)
            ? closestToZeroLensSphAb.name
            : absMinResSphAb.name;

          // Add warning if this lens is not the best monofocal
          if (lens.lens_name !== bestMonoLens) {
            warnings.push(`Another monofocal lens achieves the postoperative spherical aberration target better (click the "Compatible" button to see the better option) `);
          }
        }
      }
      // ============================================================================
    
  
      if (lens.lens_location !== "Posterior chamber (typical)") {
        warnings.push(`Lens location not in the posterior chamber`);
      }
  
      if (warnings.length > 0) {
        lensParamWarningList.push({ name: lens.lens_name, warnings: warnings });
      } else {
        mainLensList.push(lens.lens_name);
      }
  });
  // console.log(mainLensList)

  

  // ==============================================================================
    //Combined the data from lensParamWarningList and data from lensContraWarningList
    const combinedWarningList = new Set([
      ...lensContraWarningList.map(item => item.name),
      ...lensParamWarningList.map(item => item.name)
    ]);

    // Filter props.lensData to create the new array by filtering out lenses that are in the combined warning list
      const nextArray = props.lensData && props.lensData.filter(lens =>
        !combinedWarningList.has(lens.lens_name)
      );

    //Sort the lenses in nextArray from trifocal to edof to toric to monofocal
    const sortedArray = nextArray && nextArray.sort((a, b) => {
      // Prioritize lenses that correct both near and intermediate vision
      const aCorrectsBoth = a.corrects_near_vision === 'Yes' && a.corrects_intermediate_vision === 'Yes';
      const bCorrectsBoth = b.corrects_near_vision === 'Yes' && b.corrects_intermediate_vision === 'Yes';

      if (aCorrectsBoth && !bCorrectsBoth) return -1;
      if (!aCorrectsBoth && bCorrectsBoth) return 1;

      // If both or neither correct both visions, prioritize intermediate vision correction
      if (a.corrects_intermediate_vision === 'Yes' && b.corrects_intermediate_vision !== 'Yes') return -1;
      if (a.corrects_intermediate_vision !== 'Yes' && b.corrects_intermediate_vision === 'Yes') return 1;

      // Then sort by astigmatism minimum value (descending order)
      if (a.lens_astig_min * 1 > b.lens_astig_min * 1) return -1;
      if (a.lens_astig_min * 1 < b.lens_astig_min * 1) return 1;

      return 0; // If all criteria are the same, keep original order
    });

    // create chosenArray before pushing new values
    let chosenArray = [];

    // Push sorted lenses to chosenArray
    sortedArray && sortedArray.map(lens => {
      chosenArray.push(lens.lens_name);
    });

    // console.log(chosenArray)
 
    //set ptLensList to be equal to chosenArray so it is available to Paperwork.js
    useEffect(() => {
      props.setPtLensList(chosenArray)
    }, [])

    // add Cataract vision to chosenArray so it doesn't come up as contraindicated in lens dropdown
    const filteredArray = ['Cataract vision', ...chosenArray];

  // //============================================================================

  // function bestLens(){
  //   if(props.lensOption === chosenArray[0])
  //   {
  //     return props.setChoseBestLens(true)
  //   }
  //   else {
  //     return props.setChoseBestLens(false)
  //   }
  // }


  // //================================================================================
  

  const customSelectStyle = {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    zIndex: '8',
    borderRadius: '10px',
    background: '#fff',
  };

  const selectDisplayStyle = {
    fontSize: '18px',
    fontWeight: '600',
    background: 'white',
    color: props.selectedColor,
    border: '1px solid #ccc',
    padding: '8px',
    cursor: 'pointer',
    borderRadius: '10px', 
    position: 'relative', // Required for the arrow
  };


  const arrowStyle = {
    content: '""',
    position: 'absolute',
    top: '50%',
    right: '10px',
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid black',
    transform: 'translateY(-50%)',
  };


  const optionsContainerStyle = {
    border: '1px solid #ccc',
    borderTop: 'none',
    backgroundColor: 'white',
    maxHeight: '150px',
    overflowY: 'auto',
    zIndex: 1,
    borderRadius: '0 0 10px 10px', // Rounded corners for the bottom of the options container
  };
  

  const optionStyle = {
    padding: '8px',
    cursor: 'pointer',
    fontSize: '18px',
  };

  const optionHoverStyle = {
    backgroundColor: '#f1f1f1',
  };

  let liWarnStyle = {
    fontSize: '20px',
  }


  let compatibleButtonStyleFalse = {
    border: '1px solid #F2F3F4',
    padding: '5px 10px 1px 10px',
    margin: '2px 2px -8px 2px',
    fontSize: '18px',
    color: '#7D7E7F',
    width: '126px',
    height: '26px',
    borderRadius: '5px',
    background: '#fff',
    textAlign:'center',
    position: 'absolute',
    right: '150px',
    bottom: '14px',
    zIndex: '10',
    cursor: 'pointer',
  }

  let compatibleButtonStyleTrue = {
    border: '3px solid #4CBB17',
    padding: '3px 8px',
    margin: '2px 2px -8px 2px',
    fontSize: '18px',
    fontWeight: '600',
    color: '#4CBB17',
    width: '126px',
    height: '22px',
    borderRadius: '5px',
    background: '#FFF',
    textAlign:'center',
    position: 'absolute',
    right: '150px',
    bottom: '14px',
    zIndex: '10',
    cursor: 'pointer',
  }
  let utdButtonStyleFalse = {
    border: '1px solid #F2F3F4',
    padding: '5px 10px 1px 10px',
    margin: '2px 2px -8px 2px',
    fontSize: '18px',
    color: '#7D7E7F',
    width: '126px',
    height: '26px',
    borderRadius: '5px',
    background: '#fff',
    textAlign:'center',
    position: 'absolute',
    right: '0px',
    bottom: '14px',
    zIndex: '10',
    cursor: 'pointer',
  }

  let utdButtonStyleTrue = {
    border: '3px solid #4CBB17',
    padding: '3px 8px',
    margin: '2px 2px -8px 2px',
    fontSize: '18px',
    fontWeight: '600',
    color: '#4CBB17',
    width: '126px',
    height: '22px',
    borderRadius: '5px',
    background: '#FFF',
    textAlign:'center',
    position: 'absolute',
    right: '0px',
    bottom: '14px',
    zIndex: '10',
    cursor: 'pointer',
  }

  let buttonStyleTrue = {
    fontSize: '22px',
    margin: '40px 30px 0 30px',
    padding: '20px',
    background: '#007bff',
    border: '1px solid #007bff',
    borderRadius: '5px',
    color: '#FFF',
    textAlign: 'center',
    textDecoration: 'none',
  }

  let logoStyle = {
    height: '60px',
    margin: 'auto 3px',
  }


  const updatedLensData = [{ lens_name: 'Cataract vision' }, ...props.lensData];

  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  return(
    <Fragment>
      {
        props.selectedColor === 'red'
        ? 
        <div
        style={{
          width: '80vw',
          height: '80vh',
          zIndex: '9',
          background: '#efefef',
          borderRadius: '30px',
          opacity: '0.96',
          position: 'fixed',
          padding: '20px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <p 
          style={{
            fontSize: '22px',
          }}
        >
          The 
          <span
            style={{
              fontWeight: '600',
              margin: '0 10px',
            }}
          >
            {props.lensOption} 
          </span>
          would not be a good option for your eye because:
        </p>
          <ul>
          {
          lensParamWarningList && lensParamWarningList.map((w) => (
            w.name === props.lensOption
            ? w.warnings && w.warnings.map((m, i) => (
              <li 
                key={i}
                style={liWarnStyle}
              >
                {m}
              </li>
            ))
            : null
          ))
        }
        {
          lensContraWarningList && lensContraWarningList.map((w) => (
            w.name === props.lensOption
            ? w.warning && w.warning.map((m, i) => (
              <li 
                key={i}
                style={liWarnStyle}
              >
                Your eye has a history of {m}
              </li>
            ))
            : null
          ))
        }
          </ul>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div 
              style={buttonStyleTrue}
              onClick={() => props.setSelectedColor('black')}
            >
                Physician override
            </div>
          </div>
      </div>
      
        : null
      }
      <div style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '0 0 30px 0',
          position: 'absolute',
          right: '18px',
          bottom: '16px',
          zIndex: '10',
          borderRadius: '10px',
          background: '#fff',
        }}>
          <div
            style={{position: 'relative'}}
          >
            {
              props.onlyCompatible && props.onlyCompatible === true
              ? 
                <div 
                  onClick={() => props.setOnlyCompatible(false)}
                  style={compatibleButtonStyleTrue}
                >
                    Compatible
                </div>
              : 
                <div 
                onClick={() => props.setOnlyCompatible(true)}
                style={compatibleButtonStyleFalse}
                >
                    Compatible
                </div>
            }
          </div>
        <div style={{
            display: 'flex',
          }}>
          <div style={customSelectStyle}>
            <div
              style={selectDisplayStyle}
              onClick={() => setIsOpen(!isOpen)}
            >
              {props.lensOption 
                ? <>
                    {props.lensOption}
                    <FeePicker
                      lensOption={props.lensOption}
                      cri={props.cri}
                      flacs={props.flacs}
                      lensHospitalFee={props.lensHospitalFee}
                      setLensHospFee={props.setLensHospFee}
                      lensPhysicianFee={props.lensPhysicianFee}
                      setLensPhysFee={props.setLensPhysFee}
                      lensCriFee={props.lensCriFee}
                      lensFlacsFee={props.lensFlacsFee}
                      oopTotal={props.oopTotal}
                      setOopTotal={props.setOopTotal}
                      oopPhysician={props.oopPhysician}
                      setOopPhysician={props.setOopPhysician}
                      oopHospital={props.oopHospital}
                      setOopHospital={props.setOopHospital}
                    />                 
                  </> 
                : 'Select an option'}
              <div style={arrowStyle}></div> {/* Arrow */}
            </div>
            {isOpen && (
              <div style={optionsContainerStyle}>
                {(
                    props.onlyCompatible 
                    ? updatedLensData
                        .filter(l => filteredArray.some(lens => lens === l.lens_name))
                        .sort((a, b) => filteredArray.indexOf(a.lens_name) - filteredArray.indexOf(b.lens_name))
                    : updatedLensData
                  ).map((l, i) => {
                  const isFilteredLensData = filteredArray && filteredArray.some(lens => lens === l.lens_name);
                  const textColor = (isFilteredLensData) ? '#000' : 'red';

                  const key = l.lens_name;

                  return (
                    <React.Fragment key={key}>
                      {
                        // Are compatible filters off?
                        (
                          props.onlyCompatible === false 
                        )
                          ? (
                            <div
                              ref={props.lensOption === l.lens_name ? selectedOptionRef : null}
                              onClick={() => {
                                handleOptionClick(l.lens_name);
                                props.setSelectedColor(textColor);
                              }}
                              onMouseEnter={() => setHoveredIndex(i)}
                              onMouseLeave={() => setHoveredIndex(null)}
                              style={{
                                ...optionStyle,
                                color: textColor,
                                ...(hoveredIndex === i ? optionHoverStyle : {}),
                              }}
                            >
                                    <div 
                                      style={{
                                        display: 'flex',
                                      }}
                                    >
                               
                                      {
                                        l.lens_name === "Cataract vision"
                                        ? 
                                        <BsEye
                                          style={{
                                            fontSize: '30px',
                                            margin: '3px',
                                          }}
                                        />
                                        : 
                                        l.corrects_intermediate_vision === 'Yes'
                                        &&
                                        l.corrects_near_vision === 'Yes'
                                        && 
                                        l.lens_astig_min*1 > 0
                                        ? 
                                          <img 
                                            src={IconToricTrifocal}
                                            style={logoStyle}
                                          />
                                        : 
                                        l.corrects_intermediate_vision === 'Yes'
                                        &&
                                        l.corrects_near_vision === 'Yes'
                                        && 
                                        l.lens_astig_min*1 === 0
                                        ? <img 
                                            src={IconTrifocal}
                                            style={logoStyle}
                                          />
                                        : 
                                        l.corrects_intermediate_vision === 'Yes'
                                        &&
                                        l.corrects_near_vision === 'No'
                                        && 
                                        l.lens_astig_min*1 > 0
                                        ? <img 
                                            src={IconToricEdof}
                                            style={logoStyle}
                                          />
                                        :
                                        l.corrects_intermediate_vision === 'Yes'
                                        &&
                                        l.corrects_near_vision === 'No'
                                        && 
                                        l.lens_astig_min*1 === 0
                                        ? <img 
                                            src={IconEdof}
                                            style={logoStyle}
                                          />
                                        : 
                                        l.corrects_intermediate_vision === 'No'
                                        &&
                                        l.corrects_near_vision === 'No'
                                        && 
                                        l.lens_astig_min*1 > 0
                                        ? <img 
                                            src={IconToric}
                                            style={logoStyle}
                                          />
                                        : 
                                        l.corrects_intermediate_vision === 'No'
                                        &&
                                        l.corrects_near_vision === 'No'
                                        && 
                                        l.lens_astig_min*1 === 0
                                        ? <img 
                                            src={IconMonofocal}
                                            style={logoStyle}
                                          />
                                        : null
                                      }
                                    <div
                                      style={{margin: 'auto 0'}}
                                    >
                                      {l.lens_name}
                                    </div>
                                    </div>
                            </div>
                          )
                          : (
                            // Is compatible filter on?
                            props.onlyCompatible === true &&
                            // Then only allow lenses that aren't contraindicated
                            (isFilteredLensData)
                          )
                            ? (
                              <div
                                ref={props.lensOption === l.lens_name ? selectedOptionRef : null}
                                onClick={() => {
                                  handleOptionClick(l.lens_name);
                                  props.setSelectedColor(textColor);
                                }}
                                onMouseEnter={() => setHoveredIndex(i)}
                                onMouseLeave={() => setHoveredIndex(null)}
                                style={{
                                  ...optionStyle,
                                  ...(hoveredIndex === i ? optionHoverStyle : {}),
                                }}
                              >
                                    <div 
                                      style={{
                                        display: 'flex'
                                      }}
                                    >
                               
                                      {
                                        l.lens_name === "Cataract vision"
                                        ? 
                                          <BsEye
                                            style={{
                                              fontSize: '30px',
                                              margin: '3px',
                                            }}
                                          />
                                        : 
                                        l.corrects_intermediate_vision === 'Yes'
                                        &&
                                        l.corrects_near_vision === 'Yes'
                                        && 
                                        l.lens_astig_min*1 > 0
                                        ? 
                                          <img 
                                            src={IconToricTrifocal}
                                            style={logoStyle}
                                          />
                                        : 
                                        l.corrects_intermediate_vision === 'Yes'
                                        &&
                                        l.corrects_near_vision === 'Yes'
                                        && 
                                        l.lens_astig_min*1 === 0
                                        ? <img 
                                            src={IconTrifocal}
                                            style={logoStyle}
                                          />
                                        : 
                                        l.corrects_intermediate_vision === 'Yes'
                                        &&
                                        l.corrects_near_vision === 'No'
                                        && 
                                        l.lens_astig_min*1 > 0
                                        ? <img 
                                            src={IconToricEdof}
                                            style={logoStyle}
                                          />
                                        :
                                        l.corrects_intermediate_vision === 'Yes'
                                        &&
                                        l.corrects_near_vision === 'No'
                                        && 
                                        l.lens_astig_min*1 === 0
                                        ? <img 
                                            src={IconEdof}
                                            style={logoStyle}
                                          />
                                        : 
                                        l.corrects_intermediate_vision === 'No'
                                        &&
                                        l.corrects_near_vision === 'No'
                                        && 
                                        l.lens_astig_min*1 > 0
                                        ? <img 
                                            src={IconToric}
                                            style={logoStyle}
                                          />
                                        : 
                                        l.corrects_intermediate_vision === 'No'
                                        &&
                                        l.corrects_near_vision === 'No'
                                        && 
                                        l.lens_astig_min*1 === 0
                                        ? <img 
                                            src={IconMonofocal}
                                            style={logoStyle}
                                          />
                                        : null
                                      }
                                    <div
                                      style={{margin: 'auto 0'}}
                                    >
                                      {l.lens_name}  
                                    </div>
                                    </div>
                              </div>
                            )
                          : null
                      }
                    </React.Fragment>
                  );
                })}
              </div>
            )}


          </div>

        </div>
      </div>
      <NearPicker
        lensCorrectsNearVision={props.lensCorrectsNearVision}
        lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
        lensOption={props.lensOption}
        lensTargetNear={props.lensTargetNear}
        setLensTargetNear={props.setLensTargetNear}
        />
      <CriPicker
        lensOption={props.lensOption}

        lensBiometerTopographerAstigCutoff={props.lensBiometerTopographerAstigCutoff}
        lensBiometerTopographerAxisCutoff={props.lensBiometerTopographerAxisCutoff}
        biometerTopographerAxisDiff={props.biometerTopographerAxisDiff}
        biometerTopographerAstigDiff={props.biometerTopographerAstigDiff}
        lensCriRequire={props.lensCriRequire}
        lensCriMinAstig={props.lensCriMinAstig}
        lensCriMaxAstig={props.lensCriMaxAstig}
        lensFlacsRequire={props.lensFlacsRequire}


        cri={props.cri}
        setCri={props.setCri}
        criOffer={props.criOffer}
        criAlwaysOn={props.criAlwaysOn}
        setCriAlwaysOn={props.setCriAlwaysOn}
        criAllowToggle={props.criAllowToggle}
        setCriAllowToggle={props.setCriAllowToggle}
        criBioTopoAstigMax={props.criBioTopoAstigMax}
        criBioTopoAxisMax={props.criBioTopoAxisMax}

        equipmentPreferenceAstig={equipmentPreferenceAstig}

        ptContraFilterLength={ptContraFilter.length}

        flacs={props.flacs}
        setFlacs={props.setFlacs}
        flacsOffer={props.flacsOffer}
        setFlacsAlwaysOn={props.setFlacsAlwaysOn}
        setFlacsAllowToggle={props.setFlacsAllowToggle}
        />
      <FlacsPicker
        lensOption={props.lensOption}
        lensFlacsRequire={props.lensFlacsRequire}

        cri={props.cri}
        criOffer={props.criOffer}

        ptContraFilterLength={ptContraFilter.length}

        flacs={props.flacs}
        setFlacs={props.setFlacs}
        flacsOffer={props.flacsOffer}
        flacsAlwaysOn={props.flacsAlwaysOn}
        setFlacsAlwaysOn={props.setFlacsAlwaysOn}
        flacsAllowToggle={props.flacsAllowToggle}
        setFlacsAllowToggle={props.setFlacsAllowToggle}
        />   
  </Fragment>
  )
}






  // //+++++++++modify mainLensList to only allow 1 monofocal+++++++++++++++++++++++++++++++++++++
  // //Create array of only monovisin lenses
  // let onlyMonoArr = [];
  // let onlyMonoFilter = Object.entries(props.lensData).map((c) => (
  //   //Is the lens part of the viable lens options
  //   mainLensList.includes(c[1].lens_name)
  //     //is it a monofocal
  //   ? c[1].corrects_intermediate_vision === "No"
  //     && c[1].corrects_near_vision === "No"
  //     && c[1].lens_astig_max === '0'
  //     && c[1].lens_astig_min === '0'
  //     //create new array of only monofocal lenses
  //     ? onlyMonoArr.push({name: c[1].lens_name, sphAb: c[1].lens_sph_ab*1})
  //     : null
  //   : null
  // ))
  // // console.log(onlyMonoArr)


  // //Create variable called finalLensList so it's outside of if function
  // let finalLensList = [];
  // //If onlyMonoArr is empty, then finalLensList = mainLensList
  // if(onlyMonoArr.length < 1){
  //   mainLensList.map(m => (
  //     finalLensList.push(m)
  //   ))
  //   // console.log(mainLensList)
  // } else {
  //   //If onlyMonoArr has any data, then pick the best lens as follows:

  //   //Filter onlyMonoArr for monovision IOL that has the spherical aberration closest to zero
  //   const absMinLensSphAb = onlyMonoArr.reduce((acc, obj) =>
  //      Math.abs(0 - obj.sphAb) < Math.abs(0 - acc.sphAb) ? obj : acc
  //     );
  //     // console.log(absMinLensSphAb)

  //   //Filter onlyMonoArr for monovision IOL that has the spherical aberration that combines with the corneal spherical aberration to bring the total residual spherical aberration closest to the target total residual spherical aberration
  //   // *****  Will error on side of POSITIVE spherical aberration  ****
  //   const absMinResSphAb = onlyMonoArr.reduce((acc, obj) =>
  //      Math.abs(props.sphAbTarget*1 - (obj.sphAb*1 + props.topographerSphAb*1)) < Math.abs(props.sphAbTarget*1 - (acc.sphAb*1 + props.topographerSphAb*1)) ? obj : acc
  //     );
  //     // console.log(absMinResSphAb)
  //     // console.log(props.topographerSphAb)
  //     // console.log(props.sphAbTarget)
  //     // console.log("DCB00: " + ((props.topographerSphAb*1 - 0.26) - props.sphAbTarget*1))
  //     // console.log("Envista: " + ((props.topographerSphAb*1 - 0.00) - props.sphAbTarget*1))

  //   //is topographerHoa more than globalHoaMax?
  //   const bestMonoLens =
  //     (props.topographerHoa*1 > props.hoaMaxPreference*1)
  //       //if yes, then go with the IOL with the least aberration
  //       ? absMinLensSphAb.name
  //       //if no, then go with the IOL that brings the residual spherical aberration closest to target
  //       : absMinResSphAb.name

  //     // console.log(bestMonoLens)

  //   // go through onlyMonoArr and push all but bestMonoLens to badMonoArr
  //   let badMonoArr = []
  //   let badMonoFilter = Object.entries(onlyMonoArr).map((m) => (
  //     m[1].name !== bestMonoLens
  //       ? badMonoArr.push(m[1].name)
  //       : null
  //     ))
  //     // console.log(badMonoArr)

  //   // go through mainLensList and pop monofocal lenses in badMonoArr
  //   finalLensList = mainLensList.filter( ( el ) => !badMonoArr.includes( el ) );
  // }
    
  // // console.log(finalLensList)
